export default {
  error: {
    title: 'Page introuvable',
    content: `<p>La page que vous recherchez ne semble pas disponible.</p>`,
    button: {
      text: "Retourner à la page d'accueil",
      route: 'Home',
    },
  },
};
